import { Link } from "react-router-dom";

const Privacy = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Privacy</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

    {/* <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" >
      <div class="homecare-hero px-4 py-3 my-5 text-center">
        <h1 class="display-5 fw-bold text-primary">About Compliance Plus</h1>
      </div>
      </section> */}


      <section>
<div class="container px-4 py-5" id="featured-3">
<div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
  <div class="feature col">
    <h3 class="fs-2 text-body-emphasis">Compliance Plus Privacy Statement</h3>
  <p></p>
  Last Updated: August 19, 2024
  <p></p>
  <ol>
  <li><strong>Introduction</strong>
  <p></p>
  Compliance Plus ("we," "our," or "us") is committed to protecting the privacy of our website visitors and customers. This Privacy Statement explains how we collect, use, disclose, and safeguard your information when you visit our website, [www.complianceplus.com] (the “Site”). By accessing or using our Site, you agree to the terms of this Privacy Statement. If you do not agree with the terms, please do not use our Site.</li>
  <p></p>
  <li><strong>Information We Collect</strong>
  <p></p>
  We may collect and process the following types of information:<br></br>
  Personal Information: Information that identifies you as an individual, such as your name, email address, phone number, and company name, when you submit it through our Site.<br></br>
  Non-Personal Information: Information that does not directly identify you, including browser type, IP address, operating system, and browsing behavior on our Site.<br></br>
  Cookies and Tracking Data: We use cookies and similar tracking technologies to monitor and analyze usage patterns and enhance your experience on our Site.</li>
  <p></p>
  <li><strong>How We Use Your Information</strong>
  <p></p>
  We may use the information we collect for the following purposes:<br></br>
  To provide, operate, and maintain our Site.<br></br>
  To improve, personalize, and expand our services.<br></br>
  To communicate with you, including responding to your inquiries and sending promotional materials.<br></br>
  To analyze how our Site is used and optimize its functionality.<br></br>
  To ensure compliance with applicable laws and regulations.</li>
  <p></p>
  <li><strong>How We Share Your Information</strong>
  <p></p>
  We do not sell, trade, or rent your Personal Information to third parties. However, we may share your information in the following circumstances:<br></br>
  Service Providers: We may share your information with trusted third-party service providers who assist us in operating our Site and conducting our business.<br></br>
  Legal Obligations: We may disclose your information when required by law, court order, or governmental regulation.<br></br>
  Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new ownership.</li>
  <p></p>
  <li><strong>Data Security</strong>
  <p></p>
  We implement a variety of security measures to protect the safety of your personal information. However, no method of transmission over the Internet or electronic storage is completely secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</li>
  <p></p>
  <li><strong>Your Rights and Choices</strong>
  <p></p>
  You have the right to:<br></br>
  Request correction or deletion of your personal information.<br></br>
  Opt out of receiving marketing communications from us.<br></br>
  To exercise these rights, please contact us using the details provided below.</li>
  <p></p>
  <li><strong>Third-Party Links</strong>
  <p></p>
  Our Site may contain links to third-party websites. We are not responsible for the privacy practices or the content of these external sites. We encourage you to review the privacy policies of any third-party sites you visit.</li>
<p></p>
  <li><strong>Changes to This Privacy Statement</strong>
  <p></p>
  We may update this Privacy Statement from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Statement on our Site with the "Last Updated" date at the top.</li>
  <p></p>
  <li><strong>Contact Us</strong>
  <p></p>
  If you have any questions or concerns about this Privacy Statement, please <Link to="/contact">contact us</Link>.
  </li>
  </ol>
  </div>


</div>
</div>
</section>

    </>
    )
  };
  
  export default Privacy;