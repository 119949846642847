import { Link } from "react-router-dom";
import "./Home.css";
import { Seo } from '../layouts/Seo';

const Home = () => {
    return (
    <>


<Seo
        title="Compliance Plus - Home Care Software Platform"
        description="Enhance your home care agency with our robust solutions for scheduling, management, tracking, and compliance."
        type="webapp"
      />

    <section class="bsb-hero-1 px-0 bsb-overlay bsb-hover-pull masthead" >
    <div class="container">
      <div class="banner">
        <div class="banner_content">
          <h1>Compliance Plus</h1>
          <p class="d-none d-md-block">Home Healthcare Simplified</p>
        </div>
        <img src="/img/genai/healthcare_home_ext.png" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

    <section class="d-sm-none">
    <p>Cutting-edge home care software platform built to streamline operations and improve outcomes for agencies working with waiver programs and managed care payers. Discover how it can transform your organization's efficiency and client experiences.</p>
    </section>

    <section class="bg-light py-3 py-md-4">
    <div class="container">
      <div class="row">
        <div class="col-md-8 py-2 px-2">
          <h4>Home care management tools don't need to be confusing or complex</h4>
          {/*<h4>Software Platform for managed home health care</h4>*/}
          <div>Compliance Plus is designed with day-to-day users in mind, but built with the deep experience of dealing with complicated compliance rules and processes.  With Compliance Plus, you can feel confident knowing it will ease your workload and enhance your compliance reporting.</div>
          <Link to="/homecare" class="btn btn-xs btn-primary">Read More</Link>

        </div>
        <div class="col-md-4 pt-4 px-4 bg-med-gray rounded-3 ">
          <ul class="list-unstyled">
            <li><img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img> Automations for aggregators</li>
            <li><img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img> Monitor and manage revenue lifecycle</li>
            <li><img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img> Invoice and Billing automations for X12</li>
            <li><Link to="/homecare" class="btn btn-xs btn-primary">Read More</Link></li>
          </ul>
        </div>
      </div>
    </div>
    </section>


    <section class="container pb-5 py-5">
    <div class="row align-items-md-stretch">
          <div class="col-md-6">
            <div class="h-100 p-3 bg-light border rounded-3 top-stripe-blue">
              <h3>Home Care</h3>
              <p>Position your agency for the future with Compliance Plus for your scheduling, management, tracking, and compliance. 
              Streamline operations, ensure compliance, and improve service delivery with our state-of-the-art comprehensive management tools.
              See what the future can be. Compliance Plus, it's how software should be made!
              </p>
              {/*<<p>Enhance your home care agency with our robust solutions for scheduling, management, tracking, and compliance. Streamline operations, ensure compliance, and improve service delivery with our comprehensive tools.</p>
              <p>Solutions for scheduling, management, tracking and compliance for your home care agency.</p>*/}
              <Link to="/homecare" class="btn btn-secondary">Learn About Home Care Solutions</Link>
            </div>
          </div>
          <div class="col-md-6">
            <div class="h-100 p-3 bg-light border rounded-3 top-stripe-orange">
              <h3>Adult Day Health Care</h3>
              <p>Our advanced management tools are tailored specifically for adult day health care facilities, offering seamless tracking and efficient management of both guests and staff at your facility.
              Compliance Plus kiosk systems combine affordability, top-notch security, and full adherence to all state and program regulations.
              </p><br></br>
              {/*<p>Our robust management tools are designed specifically for adult day health care facilities, enabling seamless tracking and management at your facility of both guests and workers.</p>
              <p>Management tools for your elder care facility to manage and track health care visits for your residents.</p>*/}
              <Link to="/adultday" class="btn btn-secondary">Learn About Adult Day Health Care Solutions</Link>
            </div>
          </div>
    </div>
    </section>

    <section class="bg-light py-3 py-md-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="circle-tile ">
              <div class="circle-tile-heading dark-blue"><i class="fa fa-location-dot fa-fw fa-3x"></i></div>
              <div class="circle-tile-content dark-blue">
                <div class="circle-tile-description text-faded"> EVV Visits</div>
                <div class="circle-tile-number text-faded ">100+ million</div>
                <a class="circle-tile-footer" href="/homecare">More Info <i class="fa fa-chevron-circle-right"></i></a>
              </div>
            </div>
          </div>
          
          <div class="col-lg-4 col-sm-12">
            <div class="circle-tile">
              <div class="circle-tile-heading blue"><i class="fa fa-user-nurse fa-fw fa-3x"></i></div>
              <div class="circle-tile-content blue">
                <div class="circle-tile-description text-faded"> Combined years in homecare </div>
                <div class="circle-tile-number text-faded ">75+</div>
                <a class="circle-tile-footer" href="/homecare">More Info <i class="fa fa-chevron-circle-right"></i></a>
              </div>
            </div>
          </div> 

          <div class="col-lg-4 col-sm-12">
            <div class="circle-tile ">
              <div class="circle-tile-heading purple"><i class="fa fa-bell fa-fw fa-3x"></i></div>
              <div class="circle-tile-content purple">
                <div class="circle-tile-description text-faded"> EVV pioneers since </div>
                <div class="circle-tile-number text-faded ">1991</div>
                <a class="circle-tile-footer" href="/homecare">More Info <i class="fa fa-chevron-circle-right"></i></a>
              </div>
            </div>
          </div> 

        </div> 
      </div>  
    </section>

    {/* <section class="py-3 py-md-5">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 col-lg-9 col-xl-8">
            <div class="d-flex">
              <div class="flex-shrink-0">
              </div>
              <div class="flex-grow-1 ms-4 ps-3">
                <figure>
                  <blockquote class="blockquote mb-4">
                    <p>
                      <i class="fas fa-quote-left fa-lg text-warning me-2"></i>
                      <span class="font-italic">Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Pariatur sint nesciunt ad itaque aperiam expedita officiis incidunt
                        minus facere, molestias quisquam impedit inventore.</span>
                      <i class="fas fa-quote-right fa-lg text-warning me-2"></i>
                    </p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    Nancy Jones from <cite title="Source Title">Great Customer</cite>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
    </div>
    </section> */}

    </>
    )
  };
  
  export default Home;