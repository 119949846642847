

const ComplianceAssurance = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Compliance Assurance</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>
    <section>
    <div class="container px-4 py-5" id="featured-3">
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
        <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">Seamless Compliance with EVV Regulations</h3>
        <p>With the passage of the federal law called the "21st Century CURES Act" compliance with Electronic Visit Verification &#40;EVV&#41; regulations is more critical than ever. Compliance Plus ensures that your agency adheres to all relevant regulations, integrates seamlessly with state aggregators, and streamlines billing processes. This helps you maintain the highest standards of care while minimizing your administrative burdens.</p>
        <p><h4 class="fs-2 text-body-emphasis">Key Features</h4></p>
        <ol>
          <li><strong>EVV Compliance</strong>
            <ul>
              <li>Meet all federal and state EVV requirements with our fully compliant system.</li>
              <li>Capture and verify visit details, including worker identity, service location, and time.</li>
              <li>Store data securely for easy access and audits.</li>
              <li>Use GPS mobile apps, telephony, or FOBs to collect verified clock-ins and outs.</li>
            </ul>
          </li>
          <li><strong>Seamless Integration</strong>
            <ul>
            <li>Integrate effortlessly with multiple state EVV aggregators for compliance and data reporting.</li>
            <li>Compliance Plus is compatible with all state systems and adaptable to ever changing regulations.</li>
            </ul>
          </li>
          <li><strong>Efficient Billing</strong>
            <ul>
            <li>Streamline billing processes with accurate and compliant data.</li>
            <li>Generate invoices based on verified visit information, reducing errors and disputes.</li>
            <li>Fully automated 835 e-billing eliminates the need & expense of clearinghouses.</li>
            <li>Ensure timely reimbursement by aligning with payor requirements and standards, and handling 835 electronic remittance advices &#40;RAs&#41;.</li>
            </ul>
          </li>
          <li><strong>Comprehensive Reporting</strong>
            <ul>
            <li>Generate detailed reports for compliance audits and internal reviews.</li>
            <li>Access real-time data on worker activities, visit durations, and compliance status.</li>
            <li>ALWAYS know where you stand financially - what you have and have not yet billed and collected.</li>
            </ul>
          </li>
          </ol>
          <p><h4 class="fs-2 text-body-emphasis">Why Choose Compliance Plus?</h4></p>
          <ul>
            <li><strong>Regulatory Expertise:</strong> Compliance Plus helps you stay ahead of regulatory changes with a system designed to adapt and comply with emerging new requirements.</li>
            <li><strong>Data Security:</strong> Protect sensitive information with secure data storage, encrypted in transit and at rest.</li>
            <li><strong>User-friendly Interface:</strong> Easily navigate the system to get all the information you need to effectively manage multiple locations.</li>
            <li><strong>Reliable Support:</strong> Our dedicated support team is available to assist you with any technical issues.</li>
          </ul>
        <p><h4 class="fs-2 text-body-emphasis">Streamline Compliance and Care Management</h4></p>
        Compliance Plus simplifies the complexities of EVV compliance and care management. By providing a reliable, integrated solution, incorporating all three methods of EVV, we help you focus on delivering high-quality care without the administrative headaches. Trust our platform to ensure compliance, improve efficiency, and enhance the overall quality of your care services.
        </div>
      </div>
    </div>
    </section>
    </>
    )
  };
  
  export default ComplianceAssurance;