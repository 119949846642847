import { Link } from "react-router-dom";
import "./AdultDayHome.css";
import { Seo } from '../layouts/Seo';

const AdultDayHome = () => {

    return (
    <>

<Seo
        title="Compliance Plus - Adult Day Health Care Software Platform"
        description="Our robust management tools are designed specifically for adult day health care facilities, enabling seamless tracking and management at your facility of both guests and workers."
        type="webapp"
      />
    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />

    {/*<section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" >
      <div class="homecare-hero px-4 py-5 my-5 text-center">
        <h1 class="display-5 fw-bold text-primary">Compliance Plus for Adult Day Health Care</h1>
        <div class="col-lg-6 mx-auto">
          <p class="lead mb-4">A new, easy to use, simple to manage system for tracking program participants.</p>
        </div>
      </div>
      </section>*/}


    <section class="bsb-hero-1 px-0 bsb-overlay bsb-hover-pull masthead-ad" >
    <div class="container">
      <div class="banner-ad">
        <div class="banner_content_ad">
          <h1>Compliance Plus for Adult Day Health Care</h1>
          <p class="d-none d-md-block">A new, easy to use, simple to manage system for tracking program participants.</p>
        </div>
        <img src="/img/genai/adultday_home_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>


    <section class="bg-light py-3 py-md-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6 py-2 px-3">
          <h5>Revolutionize Your Adult Day Health Care Service</h5>

          <div><strong>Compliance Plus Smart Automation</strong> intuitive software solution empowers your staff, enhances client care, and streamlines your operations. We keep track of the details – so you don’t have to!</div>

        </div>
        <div class="col-md-6 py-2 px-3">

            <h5><strong>Facility Compliance</strong></h5>  
            <div>We understand the unique challenges faced by adult day health care facilities. Our user-friendly software is designed specifically for owners and managers like you, providing the tools needed to ensure efficient operations and exceptional care for your clients.</div>

        </div>
      </div>
    </div>
    </section>



 {/* <section>

 <div class="container px-5 py-5 my-5">
  <div class="row">
      <div class="col-auto text-center ">
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
        <h5 class="m-2">
            <span class="badge rounded-pill bg-light border">&nbsp;</span>
        </h5>
        <div class="row h-50">
            <div class="col border-end order">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
    </div>

      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p class="card-text">Simple, secure kiosk model for fast clock-in and clock-out.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
          <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p class="card-text">Comprehensive, HIPAA-compliant participant profiles.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
              <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p>Pre-scheduling and “ad hoc” attendance management.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
              <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p>Authorization management and complete care planning.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
              <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p>Compliance with all payer requirements including Medicaid and Medicare.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
              <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p>Automatic billing through customized payer profiles.</p>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
              <div class="col border-end">&nbsp;</div>
              <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
              <span class="badge rounded-pill bg-light border">&nbsp;</span>
          </h5>
          <div class="row h-50">
              <div class="col">&nbsp;</div>
          </div>
      </div>
      <div class="col py-2">
          <div class="card">
              <div class="card-body">
                  <div class="float-end text-muted"></div>
                  <p>Robust security including encryption and user authentication.</p>
              </div>
          </div>
      </div>
  </div>
</div>
</section> */}


<section class="container pb-5 py-5">
    <h3>Features</h3>
    <div class="row">
        <div class="col-md-6 py-2">
            <div class="card feature-card bg-light py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-chart-line"></i> Dashboard</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-chart-line"></i>Simple Tablet Kiosk Interfaces</p>
                <p class="card-hover-text">
                    Our intuitive tablet kiosk interface makes check-ins and check-outs a breeze for both staff and clients. Enjoy seamless navigation that ensures a positive experience from the moment clients arrive.
                </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 py-2">
            <div class="card feature-card bg-light py-2 shadow-lg">
                <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
                <div class="card-title card-heading"><i class="fa-solid fa-people-arrows"></i> Real-Time Attendance</div>
                <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-people-arrows"></i> Comprehensive Client Tracking</p>
                <p class="card-hover-text">
                    Manage clients with multiple arrivals and departures effortlessly. Our system tracks attendance, allowing you to focus on providing the best care possible.
                </p>
                </div>
            </div>
        </div>

        <div class="col-md-6 py-2">
            <div class="card feature-card bg-light py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-user-doctor"></i> Comprehensive Participant Profiles</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-user-doctor"></i> Staff Management </p>
                <p class="card-hover-text">
                    Keep track of staff arrivals and departures with ease. Compliance Plus helps you plan staffing requirements, monitor attendance, and ensure your facility runs smoothly and efficiently.
                </p>
                </div>
            </div>
        </div>


        <div class="col-md-6 py-2">
            <div class="card feature-card bg-light py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-hand-holding-medical"></i> Care Plan Management</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-hand-holding-medical"></i> Automated Billing</p>
                <p class="card-hover-text">
                    Track unlimited ins and outs for every client. Simplify your process with automated electronic billing. Generate accurate invoices for payers with just a few clicks, saving you time and reducing errors.
                </p>
                </div>
            </div>
        </div>

        {/*
        <div class="col-md-6 py-2">
            <div class="card feature-card bg-warning py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-person-circle-check"></i> Authorization Management</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-person-circle-check"></i> Authorization Management</p>
                <p class="card-hover-text">Efficient handling of authorizations and approvals for services and activities.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4 py-2">
            <div class="card feature-card bg-info py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-calendar-check"></i> Scheduling and Calendar Integration</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-calendar-check"></i> Scheduling Integration</p>
                <p class="card-hover-text">Tools for pre-scheduling partcipants and workers, with reminders and alerts as well as “ad hoc” attendance.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4 py-2">
            <div class="card feature-card bg-info py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-money-check-dollar"></i> Payer Compliance</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-money-check-dollar"></i> Payer Compliance</p>
                <p class="card-hover-text">Integration payer systems and EVV compliance to ensure adherence to all requirements.</p>
                </div>
            </div>
        </div>

        <div class="col-md-4 py-2">
            <div class="card feature-card bg-info py-2 shadow-lg">
            <img class="card-img-top" src="/img/stock/stethescope.jpg" alt=""></img>
            <div class="card-title card-heading"><i class="fa-solid fa-file-invoice-dollar"></i> Billing</div>
            <div class="card-body py-4">
                <p class="card-text"><i class="fa-solid fa-file-invoice-dollar"></i> Billing</p>
                <p class="card-hover-text">Streamlined billing processes with customizable payer profiles for accurate and timely invoicing without the need for a clearinghouse.</p>
                </div>
            </div>
        </div> */}


    </div>
</section>



<section class="bg-light py-3 py-md-4">
    <div class="container">
        <div class="row">
            <div class="offset-xl-1 col-xl-10 col-12 ">
                <div class="row px-3 pt-3 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3">
                    <div class="col-lg-9 p-4 p-md-6 pt-lg-0">
                        <h2 class="">Ready to transform your facility's management?</h2>
                        <p class="lead mb-2">
                            Contact us today to learn how Compliance Plus can revolutionize your Health Care Service
                        </p>
                    </div>
                    <div class="col-lg-3 p-4 p-md-6 pt-lg-0">
                        <Link to="/demo" class="btn btn-primary">Request Demo</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


{/*
<section>
<div class="admin-tasks-section container py-4 roundy-20">
    <h2 class="font-weight-light text-center text-muted py-3">Facility Compliance</h2>

    <div class="row">
        <div class="col-auto text-center flex-column d-none d-sm-flex">
            <h5 class="py-4">
                <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
            </h5>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Simple, secure kiosk for fast clock-in and clock-out</h5>
                </div>
            </div>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">HIPAA-compliant participant profiles</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-auto text-center flex-column d-none d-sm-flex">
            <h5 class="py-4">
                <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
            </h5>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Pre-scheduling and “ad hoc” attendance</h5>
                </div>
            </div>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Authorization and complete care planning</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-auto text-center flex-column d-none d-sm-flex">
            <h5 class="py-4">
                <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
            </h5>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Payer compliance including Medicaid and Medicare</h5>
                </div>
            </div>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Automatic billing through customized payer profiles</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-auto text-center flex-column d-none d-sm-flex">
            <h5 class="py-4">
                <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
            </h5>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Robust authentication security</h5>
                </div>
            </div>
        </div>
        <div class="col-5 py-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Encryption support for compliance</h5>
                </div>
            </div>
        </div>
    </div>

</div>
</section>


{/*
<section class="px-3 bsb-overlay bsb-hover-pull" >
<div class="container px-4 py-5" id="hanging-icons">
<h2>Benefits</h2>
<div class="row g-4 py-5 row-cols-1 row-cols-lg-2">

  <div class="col col-md-6">
    <ul>
      <li>Manage your facilities with precision and ease. Get rid of clipboards and sticky notes!</li>
      <li>Manage liability & services - always know who is and is not in your facility!</li>
      <li>Fully automated billing and payroll saves an immense amount of time!</li>
    </ul>
  </div>
  <div class="col col-md-6">
    <ul>
      <li>Integrated 837/835 eliminates clearinghouses, and dramatically reduces costs!</li>
      <li>Multiple services and plans of care can be managed for individual participants!</li>
      <li>Complete staff management, load balancing, and automatic payroll generation!</li>
    </ul>
  </div>
</div>

</div>
</section> 



<section> 
<div class="container ">
<h2 class="py-4">Pricing</h2>
<div class="row align-items-md-stretch">
  <div class="col-sm-12">
    <div class="h-60 p-3 bg-light border rounded-3">
      <p>We offer straightforward, transparent pricing information, ensuring you understand exactly what you're paying for. For those with specific needs, you can request a custom quote tailored to your unique requirements. Additionally, we provide different pricing tiers and packages designed to accommodate multi-location providers, offering flexibility and value for your organization.</p>
      {/*<p>We offer straightforward, transparent pricing information or you can request a custom quote. We do have different pricing tiers and packages for multi-location providers.
      </p>
      <Link to="/contact" class="btn btn-outline-secondary">Contact Us</Link>
    </div>
  </div>
</div>
</div>
</section>*/}


<p>&nbsp;</p>

      </>
  )
  };
  
  export default AdultDayHome;