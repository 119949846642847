

const Resources = () => {

    return (
    <>

<section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Resources</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>
    <section>
    <div class="container px-4 py-5" id="featured-3">
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
        <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">Welcome to Compliance Plus Resources</h3>
        <p>At Compliance Plus, we understand that staying compliant in today's complex regulatory landscape can be challenging. That's why we've curated a comprehensive set of resources specifically designed for agencies to enhance their compliance efforts and streamline their processes.</p>
        <p><h4 class="fs-2 text-body-emphasis">Key Resources for Agencies</h4></p>
        <ol>
          <li><strong>Compliance Management System (CMS) Toolkit</strong>
            <ul>
              <li>Access our toolkit designed to help you implement and manage your compliance management system effectively. This includes templates, guidelines, and best practices to ensure your CMS aligns with industry standards.</li>
            </ul>
          </li>
          <li><strong>Regulatory Updates and Insights</strong>
            <ul>
            <li>Stay informed with our regularly updated news section featuring the latest regulatory changes and insights. Our expert analysis helps you understand how new regulations impact your operations.</li>
            </ul>
          </li>
          <li><strong>Best Practice Guides</strong>
            <ul>
            <li>Download our detailed best practice guides on key compliance areas. These guides provide actionable strategies for managing risk, conducting audits, and ensuring adherence to regulatory requirements.</li>
            </ul>
          </li>
          <li><strong>Webinars and Workshops</strong>
            <ul>
            <li>Participate in our live webinars and workshops led by industry experts. These interactive sessions cover a variety of topics, including emerging compliance trends and practical compliance solutions.</li>
            </ul>
          </li>
          <li><strong>Support and Consultation Services</strong>
            <ul>
            <li>Get personalized support from our compliance experts. Whether you need help with a specific compliance challenge or ongoing consultation, our team is here to assist you.</li>
            </ul>
          </li>
          </ol>
        <p><h4 class="fs-2 text-body-emphasis">How to Get Started</h4></p>
        Getting started with Compliance Plus is easy. Explore our resources, sign up for a demo, and reach out to our support team to discover how we can help your agency achieve and maintain compliance.
        </div>
      </div>
    </div>
    </section>


    </>
    )
  };
  
  export default Resources;