

const AutomatedAlerts = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Automated Alerts</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>
    <section>
    <div class="container px-4 py-5" id="featured-3">
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
        <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">Stay Informed with Instant Notifications</h3>
        <p>Compliance Plus keeps you informed about any missed visits or deviations from the care plan. By providing real-time notifications, Compliance Plus helps ensure that care delivery is reliable and responsive, giving you the ability to address issues promptly - before they become crises.</p>
        <p><h4 class="fs-2 text-body-emphasis">Key Features</h4></p>
        <ol>
          <li><strong>Real-time Notifications</strong>
            <ul>
              <li>Receive immediate alerts for any missed visits.</li>
              <li>REALTIME WORKER ALARM TO CLOCK OUT!</li>
              <li>Stay informed about workers' punctuality.</li>
            </ul>
          </li>
          <li><strong>Regulatory Compliance Management</strong>
            <ul>
            <li><strong>Up-to-Date Standards:</strong> Compliance Plus helps you stay current with evolving regulations and standards, so you can ensure your practices are always compliant.</li>
            <li><strong>Documentation and Record Keeping:</strong> Compliance Plus maintains comprehensive and secure records of all compliance-related activities, making audit preparation seamless.</li>
            </ul>
          </li>
          <li><strong>Deviation Tracking</strong>
            <ul>
            <li>Monitor any deviations from the agreed care plan, including changes in the duration or late clock-ins and outs.</li>
            <li>Get notified when workers do not clock-in on time or leave early.</li>
            <li>Track and analyze patterns in deviations to improve care delivery.</li>
            </ul>
          </li>
          <li><strong>Comprehensive Alert System</strong>
            <ul>
            <li>Alerts are sent to both the manager and the worker.</li>
            <li>Set thresholds for alerts based on your specific needs and preferences.</li>
            <li>Maintain a detailed history of alerts and notifications for review and record-keeping.</li>
            </ul>
          </li>
          <li><strong>Immediate Issue Resolution</strong>
            <ul>
            <li>Quickly address and rectify issues as they arise, preventing potential problems from escalating.</li>
            <li>Ensure that any gaps in care are filled promptly and efficiently.</li>
            </ul>
          </li>
          </ol>
          <p><h4 class="fs-2 text-body-emphasis">Why Choose Compliance Plus?</h4></p>
          <ul>
            <li><strong>Enhanced Accountability:</strong> Ensure workers are punctual and alert them when it's time to clock-out (based on their clock-in time!)</li>
            <li><strong>Customizable Settings:</strong> Tailor the alert system to meet your unique care needs and preferences.</li>
          </ul>
        <p><h4 class="fs-2 text-body-emphasis">Ensuring Quality and Consistency in Care</h4></p>
        Compliance Plus is an essential tool for maintaining high standards in caregiving. By providing real time alarms to workers and timely notifications of missed visits or deviations from the care plan, we help you ensure that care delivery is consistent, reliable, and of the highest quality.
        </div>
      </div>
    </div>
    </section>
    </>
    )
  };
  
  export default AutomatedAlerts;