import { Outlet, Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './Layout.css';

// https://stackoverflow.com/questions/64623059/google-analytics-4-with-react/73354959#73354959
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

const Layout = () => {

  const year = new Date().getFullYear();

  usePageTracking();  

  return (
    <>

    <header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top header-bg">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img alt="Compliance Plus [Logo]" src="/img/logo.png"></img>&nbsp;Compliance Plus</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                </ul>
                <ul class="navbar-nav">
                <li class="nav-item">
                    <Link to="/" class="nav-link">Home</Link>
                </li>
                <li class="nav-item">
                    <Link to="/about" class="nav-link">About Us</Link>
                </li>
                <li class="nav-item">
                    <Link to="/support" class="nav-link">Support</Link>
                </li>
                {/* <li class="nav-item">
                    <Link to="/resources" class="nav-link">Resources</Link>
                </li> */}
                <li class="nav-item">
                  <Link to="contact" class="nav-link">Contact</Link>
                </li>
                <li class="nav-item">
                  <Link to="https://prod.complianceplus.com" class="nav-link">Login</Link>
                </li>
                </ul>&nbsp; &nbsp; &nbsp; &nbsp;
                <Link to="demo" class="ms-4 btn btn-danger">Request A Demo</Link>
            </div>
        </div>
    </nav>
    </header>

    <Outlet />

    <footer class="pt-4 pt-md-5 border-top footer-bg">
    <div class="row">
      <div class="col-sm-6 col-md-3 px-5">
      <a class="footer-brand" href="/"><img alt="Compliance + [Logo]" src="/img/logo.png"></img>&nbsp;Compliance Plus</a>
      <ul class="list-unstyled text-small px-5 mx-1">
          <li class="mb-1"><div class="footer-subheading">Give us a call</div><a class="footer-link-dark" href="tel://800.655.6515">800.655.6515</a></li>
          <li class="mb-1"><div class="footer-subheading">Email us</div><a class="footer-link-dark" href="mailto:info@complianceplus.com">info@complianceplus.com</a></li>
      </ul>
      </div>
      <div class="col-sm-6 col-md-3 px-5">
        <h5>Solutions</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><Link to="/homecare" class="footer-link-dark" href="/homecare">Home Care</Link></li>
          <li class="mb-1"><Link to="/adultday" class="footer-link-dark" href="/homecare">Adult Day Health Care</Link></li>
        </ul>
      </div>
      <div class="col-sm-6 col-md-3 px-5">
        <h5>Resources</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><a class="footer-link-dark" href="/about">About Compliance Plus</a></li>
          <li class="mb-1"><a class="footer-link-dark" href="privacy">Privacy</a></li>
          <li class="mb-1"><a class="footer-link-dark" href="terms">Terms</a></li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3 px-5">
        <img class="appstore_icons" src="/img/appstore.png" alt="Available for mobile"></img>
      </div>
      <div class="row px-5">
        <div class="col-12 copyright">© 2023 - {year}. All rights reserved ThePW</div>
        </div>
      </div>

    </footer>

    </>
  )
};

export default Layout;