import HubspotForm from 'react-hubspot-form'
import { Link } from "react-router-dom";


const Contact = () => {

  return (
  <>

<section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Contact Us</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

   <div class="container">
    
    <div class="row">

      <div class="col-md-6">
          <h3 class="rscontact-title">Contact Info</h3>
                {/* <p><i class="fa-fw fa-solid fa-map-pin"></i>PO Box 425 Drexel Hill PA 19026, USA</p> */}
                <ul class="contact_phone">
                    <li>Address: PO Box 425, Drexel Hill, PA 19026 USA</li>
                    <li>
                        Phone:  
                        <a href="tel:+16109553408"> +1 800.655.6515 (US)</a>
                    </li>
                    <li>
                      Email: 
                      <Link to="mailto:info@complianceplus.com"> info@complianceplus.com</Link>
                    </li>
              </ul>

              Find us on social media:
                <ul class="contact_social_icon">
                  <li>
                      <a class="social_link" href="https://twitter.com/complianceplus" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-square-x-twitter"></i>Twitter
                      </a>
                  </li>
                  <li>
                      <a href="https://www.facebook.com/complianceplus" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-facebook"></i>Facebook
                      </a>
                  </li>
                  <li>
                      <a href="https://www.linkedin.com/company/complianceplus" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-linkedin"></i>linkedin
                      </a>
                  </li>
                  <li>
                      <a href="https://www.instagram.com/complianceplus/" target="_blank" rel="noreferrer">
                        <i class="fa-brands fa-fw fa-instagram" aria-hidden="true"></i>Instagram
                      </a>
                  </li>
              </ul>
      </div>

      <div class="col-md-6">
          <h2>We Want To Hear From You</h2>
      <HubspotForm
                    portalId='46914512'
                    formId='4ff2248f-2d3c-4773-bf43-638d1062e62f'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />
      </div>

    </div>
    </div> 




  </>
  )
};

 
export default Contact;