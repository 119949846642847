
import { Link } from "react-router-dom";
import "./HomeCareHome.css";
import { Seo } from '../layouts/Seo';

const HomeCareHome = () => {

  return (
    <>

    <Seo
        title="Compliance Plus - Home Care Software Platform"
        description="Enhance your home care agency with our robust solutions for scheduling, management, tracking, and compliance."
        type="webapp"
      />

  <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />

  {/*<section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" >
    <div class="homecare-hero px-4 py-5 my-5 text-center">
      <h1 class="display-5 fw-bold text-primary">Introducing Compliance + Homecare</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Cutting-edge home care software built to streamline operations and improve outcomes for agencies working with waiver programs and managed care payers. Discover how it can transform your organization's efficiency and client experiences.</p>
      </div>
    </div>
    </section>*/}


    <section class="bsb-hero-1 px-0 bsb-overlay bsb-hover-pull masthead-hc" >
    <div class="container">
      <div class="banner-hc">
        <div class="banner_content_hc">
          <h1>Compliance Plus for Home Care</h1>
          <p class="d-none d-md-block">Cutting-edge home care software built to streamline operations and improve outcomes for agencies working with waiver programs and managed care payers. Discover how it can transform your organization's efficiency and client experiences.</p>
        </div>
        <img src="/img/genai/medical-office-ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

{/*    <section class="px-3 bsb-overlay bsb-hover-pull" >
    <div class="container px-4 py-5" id="hanging-icons">
    <p>Compliance Plus provides a fully integrated management system</p>
    <SankeyChart />
    </div>
    </section> */}


<section class="px-3 bsb-overlay bsb-hover-pull" >

<div class="container px-4 py-5" id="hanging-icons">
<h1>Key Features</h1>
<div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
  <div class="col d-flex align-items-start">
    <div >
      <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div>
    <div>
      <h3 class="fs-2 text-body-emphasis">Comprehensive Tracking</h3>
      <p>Meticulously monitor client services, authorizations, and caregiver activities to ensure accurate care.</p>
      <Link to="/homecare/tracking" class="btn btn-primary">
        Read More
      </Link>
    </div>
  </div>
  <div class="col d-flex align-items-start">
    <div >
      <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div>
    <div>
      <h3 class="fs-2 text-body-emphasis">Automated Workflows</h3>
      <p>Streamline administrative tasks like scheduling, billing, payroll and documentation to maximize efficiency.</p>
      <Link to="/homecare/automation" class="btn btn-primary">
        Read More
      </Link>
    </div>
  </div>
  <div class="col d-flex align-items-start">
    <div >
      <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div>
    <div>
      <h3 class="fs-2 text-body-emphasis">Compliance Assurance</h3>
      <p>Robust, intelligent auditing tools ensure regulatory compliance and audit preparedness.</p><br/>
      <Link to="/homecare/compliance" class="btn btn-primary">
        Read More
      </Link>
    </div>
  </div>
</div>
</div>
</section>


<section>
<div class="admin-tasks-section container py-4 roundy-20">
<h2 class="font-weight-light text-center text-muted py-3">Streamlining Administrative Tasks</h2>

<div class="row">

    <div class="col-auto text-center flex-column d-none d-sm-flex">
        <div class="row h-50">
            <div class="col">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
        <h5 class="m-2">
            <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
        </h5>
        <div class="row h-50">
            <div class="col border-end order">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
    </div>

    <div class="col py-2">
        <div class="card">
            <div class="card-body">
                <div class="float-end text-muted"></div>
                <h4 class="card-title text-muted">Scheduling</h4>
                <p>Automated scheduling tools simplify the coordination of authorizations and worker visits, streamlining the entire process for more efficient care management. These tools enable seamless adjustments and notifications, ensuring that workers are informed.</p>
                <p>Just imagine, enter the client’s authorization and let Compliance Plus spread out the visits to assure complete authorization usage and 100% compliance with the plan of care. Compliance Plus does this and a lot more… manage scheduled, actual, and adjusted times for every visit. Track visit and authorization utilization in real time, always knowing where you stand with every client throughout the month.</p>
                {/*<p class="card-text">Automated scheduling tools simplify coordination of authorizations and caregiver visits.</p>*/}
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-auto text-center flex-column d-none d-sm-flex">
        <div class="row h-50">
            <div class="col border-end">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
        <h5 class="m-2">
        <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
        </h5>
        <div class="row h-50">
            <div class="col border-end">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
    </div>
    <div class="col py-2">
        <div class="card">
            <div class="card-body">
                <div class="float-end text-muted"></div>
                <h4 class="card-title text-muted">Documentation</h4>
                <p>Electronic visit verification (EVV) ensures accurate, real-time data capture in compliance with all federal and state regulations, enhancing accountability and transparency in care services. Compliance Plus provides a reliable source of truth for service delivery, promoting trust and adherence to care plans, and ensuring smooth audits internally and externally.</p>
               {/*<p class="card-text">Electronic visit verification (EVV) ensures accurate, real-time data capture in compliance with all federal and state regulations.</p>*/}
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-auto text-center flex-column d-none d-sm-flex">
        <div class="row h-50">
            <div class="col border-end">&nbsp;</div>
            <div class="col">&nbsp;</div>
        </div>
        <h5 class="m-2">
        <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
        </h5>
        <div class="row h-50">
            <div class="col">&nbsp;</div>
        </div>
    </div>
    <div class="col py-2">
        <div class="card">
            <div class="card-body">
                <div class="float-end text-muted"></div>
                <h4 class="card-title text-muted">Billing</h4>
                <p>Seamlessly integrated 835/837 X12 electronic billing provides comprehensive revenue cycle management (RCM), tracking authorized services, delivered services, prebilling review, invoicing, aging management, electronic billing and remittance, contractual adjustments, and more. With Compliance Plus you always know where you stand financially. This integrated billing system streamlines the billing process, reduces errors, and accelerates payment cycles, enhancing financial stability and operational efficiency.</p>
                {/*<p>Seamless integrated 835/837 X12 electronic billing with full revenue cycle management.</p>*/}
            </div>
        </div>
    </div>
</div>
</div>
</section>

<section>
<div class="container px-4 py-5" id="featured-3">
<h2 class="pb-2 border-bottom">Improving Client Satisfaction</h2>
<div class="row g-4 py-5 row-cols-1 row-cols-lg-2">
  <div class="feature col">
    {/* <div class="feature-icon d-inline-flex align-items-center justify-content-center">
    <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div> */}
    <h3 class="fs-2 text-body-emphasis">Personalized Care Plans</h3>
    <p>Care plans can be tailored to each client's unique needs, ensuring personalized and effective support. Additionally, the system manages multiple authorizations, streamlining administrative tasks and facilitating comprehensive care coordination.</p><br/>
    {/*<p>Care plans tailored to each client's unique needs and multiple authorizations.</p>*/}
    <a href="/demo" class="btn btn-sm btn-secondary">
      Request A Demo  
    </a>
  </div>
  <div class="feature col">
  {/* <div class="feature-icon d-inline-flex align-items-center justify-content-center">
    <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div> */}
    <h3 class="fs-2 text-body-emphasis">Progress Tracking</h3>
    <p>Authorization unit tracking allows you to monitor client services throughout the month, ensuring that care is consistently aligned with their needs. This system enables timely adjustments to care plans, enhancing the responsiveness and effectiveness of the services provided.</p>
    {/*<p>Monitor client services throughout the month and adjust care as needed.</p>*/}
    <a href="/demo" class="btn btn-sm btn-secondary">
      Request A Demo
    </a>
  </div>
  <div class="feature col">
  {/* <div class="feature-icon d-inline-flex align-items-center justify-content-center">
    <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div> */}
    <h3 class="fs-2 text-body-emphasis">Caregiver Collaboration</h3>
    <p>Seamless communication between you and your caregivers ensures that all aspects of care are properly coordinated and executed. This continuous exchange of information helps maintain high standards of care and promptly addresses any issues that arise.</p>
    {/*<p>Seamless communication between you and your caregivers to ensure proper care.</p>*/}
    <a href="/demo" class="btn btn-sm btn-secondary">
      Request A Demo
    </a>
  </div>
  <div class="feature col">
  {/* <div class="feature-icon d-inline-flex align-items-center justify-content-center">
    <img class="brand-bullet-icon" alt="Compliance Plus [Logo]" src="/img/logo.png"></img>
    </div> */}
    <h3 class="fs-2 text-body-emphasis">Family Engagement</h3>
    <p>Family engagement empowers clients and their families to be active participants in the care process, fostering a collaborative and supportive environment. This involvement enhances the overall care experience, ensuring that the unique needs and preferences of each client are met.</p>
    {/*<p>Empower clients and their families to be active participants in the care process.</p>*/}
    <a href="/demo" class="btn btn-sm btn-secondary">
      Request A Demo
    </a>
  </div>
</div>
</div>
</section>



<section> 
<div class="container">
<h2>Electronic Visit Verification (EVV)</h2>
<div class="row align-items-md-stretch">
  <div class="col-sm-3">
    <div class="h-60 p-3 bg-light border rounded-3">
      <h4>Real-time Tracking</h4>
      <p>Accurately track caregiver arrivals, departures, location, and the duration of each visit to ensure precise monitoring and reporting of care services.</p>
      {/*<p>Accurately track caregiver arrivals, departures, and the duration of each visit.</p>*/}
      <Link to="/homecare/evvtracking" class="btn btn-outline-secondary">Read More</Link>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="h-60 p-3 bg-light border rounded-3">
      <h4>Automated Alerts</h4>
      <p>Receive notifications for any missed visits or deviations from the care plan to promptly address and rectify any issues in care delivery.</p><br/>
      {/*<p>Receive instant notifications for any missed visits or deviations from the care plan.</p>*/}
      <Link to="/homecare/automatedalerts" class="btn btn-outline-secondary">Read More</Link>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="h-60 p-3 bg-light border rounded-3">
      <h4>Compliance Assurance</h4>
      <p>Ensure compliance with EVV regulations and seamlessly integrate with state aggregator and billing systems for efficient and accurate care management.</p>
      {/*<p>Ensure compliance with EVV regulations and integrate with state aggregator and billing systems.</p>*/}
      <Link to="/homecare/complianceassurance" class="btn btn-outline-secondary">Read More</Link>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="h-60 p-3 bg-light border rounded-3">
      <h4>Transparency</h4>
      <p>Provide clients, their families, and regulatory authorities with controlled visibility into the care you provide.</p><br/><br/>
      <Link to="/homecare/transparency" class="btn btn-outline-secondary">Read More</Link>
    </div>
  </div>
</div>
</div>
</section>


<section> 
<div class="container">
<h2 class="py-4">Pricing</h2>
<div class="row align-items-md-stretch">
  <div class="col-sm-12">
    <div class="h-60 p-3 bg-light border rounded-3">
      <p>We offer straightforward, transparent pricing information, ensuring you understand exactly what you're paying for. For those with specific needs, you can request a custom quote tailored to your unique requirements. Additionally, we provide different pricing tiers and packages designed to accommodate multi-location providers, offering flexibility and value for your organization.</p>
      {/*<p>We offer straightforward, transparent pricing information or you can request a custom quote. We do have different pricing tiers and packages for multi-location providers.</p>*/}
      <Link to="/contact" class="btn btn-outline-secondary">Contact Us</Link>
    </div>
  </div>
</div>
</div>
</section>
<p class="p-2">&nbsp;</p>



  </>
  )
};
  


  export default HomeCareHome;