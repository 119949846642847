

const EvvTracking = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Real-time Tracking</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>
    <section>
    <div class="container px-4 py-5" id="featured-3">
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
        <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">Accurate Monitoring of Caregiver Activities</h3>
        <p>In today's fast-paced world, ensuring the highest quality of care services requires precise monitoring and reporting. Compliance Plus is a comprehensive solution that accurately tracks caregivers' arrivals, departures, locations, and duration, for every visit. </p>
        <p>This electronic visit verification &#40;EVV&#41; capability is available in all of the forms approved by your state and the federal government. Compliance Plus offers three basic methods for EVV: a GPS Mobile App, Telephony, and Fixed OBjects &#40;FOB&#41;. Collectively, these methods allow for documentation of EVV from ANY client, regardless of their location or technical equipment. Compliance Plus EVV enhances transparency and accountability, giving you the peace of mind that comes with knowing your clients are receiving the care they deserve.</p>
        <p><h4 class="fs-2 text-body-emphasis">Key Features</h4></p>
        <ol>
          <li><strong>Real-time Location Tracking</strong>
            <ul>
              <li>Monitor the exact location of caregivers in real-time when they clock in and out.</li>
              <li>Ensure workers are at the correct location for the required duration.</li>
              <li>Receive instant notifications when caregivers are delayed, don't show up, or leave early.</li>
            </ul>
          </li>
          <li><strong>Accurate Time Tracking</strong>
            <ul>
            <li>Log the exact times of caregiver arrivals and departures.</li>
            <li>Record the duration of each visit for detailed reporting.</li>
            <li>Automated time-stamping eliminates manual errors and discrepancies.</li>
            <li>Actual clock-in and out times replace scheduled times on client & worker calendars.</li>
            </ul>
          </li>
          <li><strong>Comprehensive Reporting</strong>
            <ul>
            <li>Generate detailed reports on caregiver activities, including time spent at each location.</li>
            <li>Export data for billing, compliance, and audit purposes.</li>
            <li>Access historical data to track trends and improve service quality.</li>
            </ul>
          </li>
          <li><strong>Enhanced Communication</strong>
            <ul>
            <li>Immediate alerts for any deviations from the care plan.</li>
            <li>Real-time updates on the calendar for schedulers.</li>
            <li>Easy communication with workers for tasks needing completed.</li>
            <li>Comprehensive internal notes system for worker to worker communications.</li>
            </ul>
          </li>
          </ol>
        <p><h4 class="fs-2 text-body-emphasis">Empowering Better Care</h4></p>
        Compliance Plus empowers agencies with the information they need to ensure the best care. By accurately tracking payer's instructions and workers' activities, you can ensure compliance with care plans, improve efficiency, reduce costs, and provide a safer, more reliable care experience.
        </div>
      </div>
    </div>
    </section>
    </>
    )
  };
  
  export default EvvTracking;