

const Support = () => {

    return (
    <>



<link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />

<section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Support</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>


  <section>
<div class="container px-4 py-5" id="featured-3">
<div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
<div class="feature col">
<h3 class="fs-2 text-body-emphasis">Welcome to our Support Page! </h3>
<p>Our dedicated support team is here to assist you with any questions or issues you may encounter. Whether you need help navigating our platform, troubleshooting a problem, or understanding how to maximize the features of our services, we're just a click or call away. Explore our comprehensive knowledge base, FAQs, and video tutorials for step-by-step guidance. If you require further assistance, don't hesitate to reach out through our live chat, email, or phone support. We're committed to providing you with timely and effective solutions to ensure your experience with us is seamless and satisfactory.
</p>
<p>
    <ul class="contact_phone">
        <li>Phone: <a href="tel:+16109553408"> +1 800.655.6515 &#40;US&#41; </a></li>
        <li>Email: <a href="mailto:support@complianceplus.com">support@complianceplus.com</a>
        </li>
    </ul>
</p>
</div>

</div>
</div>
</section>

    </>
    )
  };
  
  export default Support;