import HubspotForm from 'react-hubspot-form'

const Demo = () => {

  return (
  <>


<section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Request A Demo</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>




    <div className="container">
        <div className="row">
          <div className="col">

            <div>Contact us today to arrange your evaluation of Compliance Plus.  
            Experience firsthand how our solution can streamline your compliance management. Our team will guide you through the platform's key features and answer any questions you may have. Fill out the form below to schedule your personalized demo and discover how Compliance Plus can meet your compliance needs effectively.
                <br/><br/>
                To arrange for your demo please call us at 800-655-6515. 

                <hr size="1"></hr>
              </div>

              <HubspotForm
                    portalId='46914512'
                    formId='9ad12819-451b-4b4a-b370-4f42728094c4'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />

          </div>
        </div>
      </div>  

  </>
  )
};

export default Demo;