import {React, StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Solutions from "./pages/Solutions";
import HomeCareHome from './pages/HomeCareHome';
import AdultDayHome from './pages/AdultDayHome';
import Contact from "./pages/Contact";
import Support from './pages/Support';
import Resources from './pages/Resources';
import Demo from './pages/Demo';
import NoPage from "./pages/NoPage";
import Tracking from "./pages/Tracking"
import Automation from './pages/Automation';
import Compliance from './pages/Compliance';
import AutomatedAlerts from './pages/AutomatedAlerts';
import ComplianceAssurance from './pages/ComplianceAssurance';
import EvvTracking from './pages/EvvTracking';
import Transparency from './pages/Transparency';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
          <Route path="homecare" element={<HomeCareHome />} />
          <Route path="adultday" element={<AdultDayHome />} />
          <Route path="solutions" element={<Solutions />} />
          <Route path="contact" element={<Contact />} />
          <Route path="support" element={<Support />} />
          <Route path="resources" element={<Resources />} />
          <Route path="demo" element={<Demo />} />
          <Route path="homecare/tracking" element={<Tracking />} />
          <Route path="homecare/automation" element={<Automation />} />
          <Route path="homecare/compliance" element={<Compliance />} />
          <Route path="homecare/automatedalerts" element={<AutomatedAlerts />} />
          <Route path="homecare/complianceassurance" element={<ComplianceAssurance />} />
          <Route path="homecare/evvtracking" element={<EvvTracking />} />
          <Route path="homecare/transparency" element={<Transparency />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />


          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

root.render(
  <StrictMode>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
