

const About = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>About Compliance Plus</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

    {/* <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" >
      <div class="homecare-hero px-4 py-3 my-5 text-center">
        <h1 class="display-5 fw-bold text-primary">About Compliance Plus</h1>
      </div>
      </section> */}


      <section>
<div class="container px-4 py-5" id="featured-3">
<div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
  <div class="feature col">
    <h3 class="fs-2 text-body-emphasis">Compliance Plus is different.</h3>
    <p>Compliance Plus is a new system, built in a new way. We pride ourselves on creating solutions from a different perspective – that of the user who actually has to work in the system every day! Unlike most other systems that are developed from the viewpoint of software developers, many of whom have never worked in or around homecare or waiver programs, Compliance Plus is designed with the end-user in mind. Our system is intuitive, user-friendly, and tailored to meet the unique needs of those working in the healthcare industry.</p>
    {/*<p>Compliance Plus is a new system, built in a new way. We pride ourselves on building solutions from a different perspective - that of the user who actually has to work in the system every day!  Most other systems are built from the perspective of the software developers, most of whom have never worked in or around homecare or waiver programs. </p>*/}
  </div>

  <div class="feature col">
    <p>Our team is built from the market up and includes pioneers in the healthcare industry, bringing some of the greatest expertise in healthcare technology. We manage a wide range of projects, from small initiatives to large-scale implementations for vendors, provider networks, and payers, while also developing our own innovative products. Compliance Plus is the culmination of decades of industry experience combined with state-of-the-art technology. Experience the difference a smart, user-centered system can make for you and your agency.</p>
    {/*<p>Our team is built from the market up and includes pioneers in the healthcare industry, with some of the greatest expertise in healthcare technology. We have and continue to manage all kinds of projects, small and large, for vendors, provider networks, and payers, along with developing our own products as well. Compliance Plus is the culmination of decades of industry experience, mixed with state-of-the-art technology. See for yourself the difference that a smart system can mean for you and your agency. </p>*/}
  </div>

  <div class="feature col">
    <p>At Compliance Plus, we are rethinking technology, process, and usability by actively listening to our customers and incorporating their feedback into our solutions. Our approach focuses on creating intuitive, user-friendly systems that streamline workflows and enhance efficiency. By prioritizing the needs and experiences of our users, we develop technology that truly supports and empowers them. We believe that the best innovations come from understanding the challenges our customers face daily, and we are committed to continuously improving our products to meet and exceed their expectations.
    </p>
  </div>
  {/*
  <div class="feature col">
    <h3 class="fs-2 text-body-emphasis">Leadership Team</h3>
    <p>pictures, name, title, short bio of key people, with links to linkedin 
    </p>
  </div>
    */}

</div>
</div>
</section>

    </>
    )
  };
  
  export default About;