import { Link } from "react-router-dom";

const Terms = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Terms of Use</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>

    {/* <section class="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" >
      <div class="homecare-hero px-4 py-3 my-5 text-center">
        <h1 class="display-5 fw-bold text-primary">About Compliance Plus</h1>
      </div>
      </section> */}


      <section>
<div class="container px-4 py-5" id="featured-3">
<div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
  <div class="feature col">
    <h3 class="fs-2 text-body-emphasis">Compliance Plus Terms of Use</h3>
  <p></p>
  Last Updated: August 19, 2024
  <p></p>
  <ol>
  <li><strong>Acceptance of Terms</strong>
  <p></p>
  Welcome to the Compliance Plus website ("Site"). By accessing and using our Site, you agree to comply with and be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, please do not use our Site.</li>
  <p></p>
  <li><strong>Use of the Site</strong>
  <p></p>
  You may use our Site for lawful purposes only. You agree not to:<br></br>
    <ul>
      <li>Use the Site in any way that violates any applicable federal, state, local, or international law or regulation.</li>
      <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm Compliance Plus or users of the Site.</li>
      <li>Impersonate or attempt to impersonate Compliance Plus, a Compliance Plus employee, another user, or any other person or entity.</li>
      <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
      <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.</li>
    </ul>
  </li>
  <p></p>
  <li><strong>Intellectual Property Rights</strong>
  <p></p>
  The content, features, and functionality on the Site, including text, graphics, logos, images, and software, are the exclusive property of Compliance Plus or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content from the Site without prior written consent from Compliance Plus.</li>
  <p></p>
  <li><strong>User Content</strong>
  If you post or submit content to our Site, including but not limited to comments, feedback, or other materials ("User Content"), you grant Compliance Plus a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, and create derivative works from such User Content throughout the world in any media.<br></br>
  You represent and warrant that you own or control all rights in and to your User Content and have the right to grant the license described above.</li>
  <p></p>
  <li><strong>Disclaimer of Warranties</strong>
  <p></p>
  The Site and its content are provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.<br></br>
  Compliance Plus does not warrant that the Site will be uninterrupted, error-free, secure, or free of viruses or other harmful components.</li>
  <p></p>
  <li><strong>Limitation of Liability</strong>
  <p></p>
  To the fullest extent permitted by law, Compliance Plus shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from:<br></br>
    <ul>
      <li>Your access to or use of, or inability to access or use, the Site.</li>
      <li>Any conduct or content of any third party on the Site.</li>
      <li>Any content obtained from the Site.</li>
      <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
    </ul>
  </li>
  <p></p>
  <li><strong>Indemnification</strong>
  <p></p>
  You agree to defend, indemnify, and hold harmless Compliance Plus, its affiliates, and their respective directors, officers, employees, agents, and licensors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Site.</li>
  <p></p>
  <li><strong>Changes to the Terms</strong>
  <p></p>
  Compliance Plus may revise these Terms at any time by updating this page. All changes are effective immediately when posted and apply to all access to and use of the Site thereafter. Your continued use of the Site following the posting of revised Terms means that you accept and agree to the changes.</li>
  <p></p>
  <li><strong>Governing Law and Jurisdiction</strong>
  <p></p>
  These Terms are governed by and construed in accordance with the laws of the United States of America and the Commonweath of Pennsylvania, without regard to its conflict of law principles. Any legal action or proceeding arising under these Terms shall be brought exclusively in the federal or state courts located in Philadelphia, Pennsylvania.</li>
  <p></p>
  <li><strong>Termination</strong>
  <p></p>
  We reserve the right, in our sole discretion, to terminate or suspend your access to all or part of the Site for any reason, without notice, including for any violation of these Terms.</li>
  <p></p>
  <li><strong>Contact Us</strong>
  <p></p>
  If you have any questions or concerns about this Privacy Statement, please <Link to="/contact">contact us</Link>.
  </li>
  </ol>
  </div>


</div>
</div>
</section>

    </>
    )
  };
  
  export default Terms;