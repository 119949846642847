

const Transparency = () => {

    return (
    <>



    <link rel="stylesheet" href="assets/css/timeline.css"  type = "text/css" />


    <section class="subpage-hero px-0 bsb-overlay bsb-hover-pull masthead-subpage" >
    <div class="container">
      <div class="banner-subpage">
        <div class="banner_content_subpage">
          <h1>Improved Transparency</h1>
        </div>
        <img src="/img/genai/med_staff_ext.jpg" alt="Compliance Plus Home Care Software Platform"/>
      </div>
    </div>
    </section>
    <section>
    <div class="container px-4 py-5" id="featured-3">
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-1">
        <div class="feature col">
        <h3 class="fs-2 text-body-emphasis">Enhancing Visibility in Care Services</h3>
        <p>Compliance Plus empowers agencies, workers, clients, families, and regulatory authorities with controlled visibility into all care provided.</p>
        <p><h4 class="fs-2 text-body-emphasis">Key Features</h4></p>
        <ol>
          <li><strong>Real-time Access</strong>
            <ul>
              <li>Compliance Plus provides a clear, accessible record of all interactions and services provided.</li>
              <li>Authorized users can see workers locations at clock-in and clock-out and schedules in real-time.</li>
            </ul>
          </li>
          <li><strong>Detailed Reporting</strong>
            <ul>
            <li>Generate comprehensive reports on care activities, including visit dates/times, duration, tasks completed, billing, and payroll, on demand.</li>
            <li>Detailed documentation for compliance with all regulatory standards and audits.</li>
            </ul>
          </li>
          <li><strong>Secure and Controlled Access</strong>
            <ul>
            <li>Secure login protocols protect client, worker, and visit data.</li>
            <li>Access based on user roles, ensure privacy and confidentiality.</li>
            </ul>
          </li>
          </ol>
          <p><h4 class="fs-2 text-body-emphasis">Why Choose Compliance Plus?</h4></p>
          <ul>
            <li><strong>Enhanced Trust:</strong> Build stronger relationships with clients and their families through open and transparent communication.</li>
            <li><strong>Accountability:</strong> Ensure caregivers meet expectations and adhere to care plans.</li>
            <li><strong>Compliance:</strong> Provide necessary documentation and visibility to regulatory authorities, ensuring adherence to standards and regulations.</li>
            <li><strong>User-friendly Interface:</strong> Easily navigate and access information, even for those less technology-abled.</li>
          </ul>
        <p><h4 class="fs-2 text-body-emphasis">Building Trust Through Transparency</h4></p>
        Compliance Plus provides a clear and comprehensive view of care delivery. Through this controlled visibility, we help build trust with clients, workers, families, and regulatory authorities  ensuring they are always informed and confident in the care provided. Compliance Plus offers every stakeholder the insights needed to make informed decisions and uphold the highest standards of care.
        </div>
      </div>
    </div>
    </section>
    </>
    )
  };
  
  export default Transparency;